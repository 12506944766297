/*
 * All reducers get two parameters passed in, state and action that occurred
 *       > state isn't entire apps state, only the part of state that this reducer is responsible for
 * */

import { SELECT_SCENARIO, GET_SCENARIO_LIST } from '../actions/index';// "state = null" is set so that we don't throw an error when app first boots up

export default function (state = null, action) {

    switch (action.type) {
        case SELECT_SCENARIO:
            return action.payload.data.scenarioSummary;
        case GET_SCENARIO_LIST:
            return action.payload.data.scenarioSummary
        default:
         	return state;
    }

}
