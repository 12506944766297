
import React, { Component } from 'react'
import { connect } from 'react-redux';
import ScenarioDetails from '../centralViews/charts/scenarioDetails';
import VTKViewer from '../centralViews/vtp/MyVTKViewerVtp';
import PlayerControls from '../centralViews/vtp/playerControls';
class ThreeDView extends Component {

  constructor(props) {
    super(props);
    this.state = {
      file: null,
    };
  }

  render() {
    //const graphWidth = this.props.dimensions.width * 0.38;
    //const graphHeight = this.props.dimensions.height * 0.85/3;
    const graphHeight = window.innerHeight * 0.70;
    const graphWidth = window.innerWidth * 0.5;
    if (this.state.file) {
      console.log(this.state.file.name);
    }
    const fileType = "vtk";

    return (

      <div style={styles.Main3DPage}>
        <div style={styles.RowStyle} >
          <div style={styles.playerMain}>
            <div style={styles.playerControls}>
              <PlayerControls />
            </div>
            <div style={styles.player}>
              <VTKViewer
                onSceneRendered={(element) => { console.log(element) }}
                width={graphWidth}
                height={graphHeight}
                sceneClassName="test-scene"
                file={this.state.file}
                className="VTK"
                backgroundColor='#132F57'
                modelColor="#bdbdbd" />
            </div>
          </div>
          <div style={styles.rightPanel}>
            <ScenarioDetails />
          </div>
        </div>
      </div>
    );
  }
}
/**
  <div style={styles.logo}>
    <LogoView />
  </div>
**/
function mapStateToProps(state) {
  return {
    fileType: state.fileType,
    //scenario: state.scenario,
    dimensions: state.dimensions
  };
}

export default connect(mapStateToProps, null)(ThreeDView);
//export default (TrackView)

const styles = {
  Main3DPage: {
    height: '100%',
    width: '100%',
    //overflow: 'auto',
    backgroundColor: 'transparent',
  },
  RowStyle: {
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
  },

  playerMain: {
    flexDirection: 'column',
    justifyContents: 'center',
    width: '60%',
    marginLeft: '1%',
    marginTop: '1%',
    marginRight: '1%',
    backgroundColor: 'transparent',
  },

  rightPanel: {
    flexDirection: 'column',
    justifyContents: 'center',
    width: '40%',
    height: '100%',
    marginLeft: '0%',
    marginRight: '1%',
    marginTop: '1%',
    marginBottom: '1%',
    backgroundColor: 'transparent',
    overflow: 'auto'
  },
  playerControls: {
    flexDirection: 'row',
    justifyContents: 'center',
    alignItems: 'center',
    width: '100%',
    height: '7%',
    marginLeft: 0,
    borderRadius: '8',
    backgroundColor: 'black',
    //overflow: 'auto'
  },
  player: {
    justifyContents: 'center',
    width: '100%',
    height: '90%',
    borderRadius: 10,
    marginTop: '1%',
    marginBottom: '1%',
    backgroundColor: 'black',
    //overflow: 'auto'
  },
  logo: {
    //flexDirection: 'row',
    justifyContents: 'center',
    width: '30%',
    height: '30%',
    marginLeft: 0,
    //marginRight: 30,
    //marginTop: 20,
    //marginBottom: 20,
    backgroundColor: 'transparent',

  },
}
