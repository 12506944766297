import React, { Component } from "react";
import { createNewUser } from "../../../actions/SecurityActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";
import * as routes from "../../routes";
class Register extends Component {
  constructor() {
    super();
    this.state = {
      username: "",
      fullName: "",
      password: "",
      confirmPassword: "",
      role: "",
      id: "",
      errors: {},
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.security && this.props.security.validToken) {
      this.props.history.push(routes.LOGIN);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  onSubmit(e) {
    e.preventDefault();
    const newUser = {
      username: this.state.username,
      fullName: this.state.fullName,
      password: this.state.password,
      confirmPassword: this.state.confirmPassword,
      id: this.state.username,
      role: this.state.role,
    };

    this.props.createNewUser(newUser, this.props.history);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    let { errors } = this.state;
    return (
      <div className="col-md-8 m-auto">
        <h1 className="display-4 text-center text-dark">Sign Up</h1>
        <p className="lead text-center text-dark">Create your Account</p>
        <form onSubmit={this.onSubmit}>
          <div className="form-group">
            <input
              type="text"
              className={classnames("form-control form-control-lg", {
                "is-invalid": errors.fullName,
              })}
              placeholder="Full Name"
              name="fullName"
              value={this.state.fullName}
              onChange={this.onChange}
            />
            {errors.fullName && (
              <div className="invalid-feedback">{errors.fullName}</div>
            )}
          </div>
          <div className="form-group">
            <input
              type="text"
              className={classnames("form-control form-control-lg", {
                "is-invalid": errors.username,
              })}
              placeholder="Email Address (Username)"
              name="username"
              value={this.state.username}
              onChange={this.onChange}
            />
            {errors.username && (
              <div className="invalid-feedback">{errors.username}</div>
            )}
          </div>
          <div className="form-group">
            <input
              type="password"
              className={classnames("form-control form-control-lg", {
                "is-invalid": errors.password,
              })}
              placeholder="Password"
              name="password"
              value={this.state.password}
              onChange={this.onChange}
            />
            {errors.password && (
              <div className="invalid-feedback">{errors.password}</div>
            )}
          </div>
          <div className="form-group">
            <input
              type="password"
              className={classnames("form-control form-control-lg", {
                "is-invalid": errors.confirmPassword,
              })}
              placeholder="Confirm Password"
              name="confirmPassword"
              value={this.state.confirmPassword}
              onChange={this.onChange}
            />
            {errors.confirmPassword && (
              <div className="invalid-feedback">{errors.confirmPassword}</div>
            )}
          </div>
          <div className="form-group">
            <input
              type="role"
              className={classnames("form-control form-control-lg", {
                "is-invalid": errors.role,
              })}
              placeholder="key"
              name="role"
              value={this.state.role}
              onChange={this.onChange}
            />
            {errors.id && <div className="invalid-feedback">{errors.role}</div>}
          </div>

          <input
            type="submit"
            className="btn btn-info btn-block mt-4"
            style={{ backgroundColor: "#6086c1" }}
          />
        </form>
      </div>
    );
  }
}

Register.propTypes = {
  createNewUser: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  security: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.errors,
  security: state.security,
});
export default connect(
  mapStateToProps,
  { createNewUser }
)(Register);
