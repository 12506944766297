import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Bar, Line } from 'react-chartjs-2';
import { styles } from './chartStyles.js';


class Charts extends React.Component {

  constructor(props) {
    super(props);
    //this.renderGraphBar = this.renderGraphBar.bind(this);
    this.renderGraphLine = this.renderGraphLine.bind(this);
    this.renderChart = this.renderChart.bind(this);

  }

  renderGraphLine(displayTable) {
    const title = displayTable.title;
    const dataset = displayTable.displayList;
    const labelList = displayTable.labelList;
    const unitLabel = displayTable.unit;
    const max = displayTable.max;
    const min = displayTable.min;
    //const max = 50;
    let data = {
      labels: labelList,
      datasets: [
        {
          label: title,
          backgroundColor: 'rgba(255,99,132,0.2)',
          borderColor: 'rgba(255,99,132,1)',
          borderWidth: 1,
          pointRadius: 0,
          hoverBackgroundColor: 'rgba(255,99,132,0.4)',
          hoverBorderColor: 'rgba(255,99,132,1)',
          data: dataset
        }
      ]
    };

    const options = {
      scaleShowGridLines: true,
      scaleGridLineColor: 'rgba(0,0,0,.05)',
      scaleGridLineWidth: 1,
      scaleShowHorizontalLines: true,
      scaleShowVerticalLines: true,
      bezierCurve: true,
      bezierCurveTension: 0.4,
      pointDot: true,
      pointDotRadius: 4,
      pointDotStrokeWidth: 1,
      pointHitDetectionRadius: 20,
      datasetStroke: true,
      datasetStrokeWidth: 2,
      datasetFill: true,
      legendTemplate: '<ul class=\"<%=name.toLowerCase()%>-legend\"><% for (var i=0; i<datasets.length; i++){%><li><span style=\"background-color:<%=datasets[i].strokeColor%>\"></span><%if(datasets[i].label){%><%=datasets[i].label%><%}%></li><%}%></ul>',
    }
    //data= data2;
    return (
      <Line
        data={data}
        options={{
          maintainAspectRatio: false,
          //responsive: true,
          legend: {
            display: false,
          },
          layout: {
            padding: {
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
            },
            margin: {
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
            },

          },
          title: {
            display: true,
            text: title,
            fontSize: 16,
            fontColor: "black",
            fontWeight: "bold",
          },
          scales: {
            yAxes: [{
              ticks: {
                max: max,
                min: min,
                fontColor: 'black'
              },
              scaleLabel: {
                display: true,
                color: 'black',
                labelString: unitLabel
              },
              fontColor: 'black'
            }],
            xAxes: [{
              ticks: {
                fontSize: 12,
                fontColor: 'black',
                maxRotation: 0,
                autoSkip: true,
                maxTicksLimit: 8,

              },
              scaleLabel: {
                display: true,
                fontSize: 12,
                labelString: 'Time (ms)',

              },
              //fontColor: 'black'
            }]
          },
        }}
      />

    );
  }

  /**
  renderGraphBar(){
    return (
      <div height={this.props.height} >
        <h2>Bar Example</h2>
        <Bar
          data={data}
          options={{
            maintainAspectRatio: false
          }}
        />
      </div>
    );
  }
 **/

  renderChart(displayTable, index) {
    return (
      <div style={styles.chartContainer} key={index} >
        {this.renderGraphLine(displayTable)}
      </div>)
  }

  render() {
    if (this.props.dimensions == null) return null;
    const width = this.props.dimensions.width * 0.38;
    const height = this.props.dimensions.height * 0.85 / 2;
    if (this.props.scenarioTimeList === null) return null;
    return (
      <div style={styles.allChartContainer}>
        {this.props.scenarioTimeList.map((dt, index) => this.renderChart(dt, index))}
      </div>
    );
  }
}

function mapStateToProps(state) {

  return {
    scenarioTimeList: state.scenarioTimeList,
    //timeControl: state.timeControlExplore,
    dimensions: state.dimensions
  };
}

function mapDispatchToProps(dispatch) {

  return bindActionCreators({}, dispatch);
}

//export default TimeList;
export default connect(mapStateToProps, mapDispatchToProps)(Charts);
