import { combineReducers } from 'redux';
import securityReducer from "./securityReducer";
import DimensionsReducer from './reducer-dimensions';
import ObjFileReducer from './reducer-obj-file';
import VtpFileReducer from './reducer-vtp-file';
import ScenarioReducer from './reducer-scenario';
import ScenarioListReducer from './reducer-get-scenario-list';
import ScenarioSummaryReducer from './reducer-scenario-summary-table';
import ScenarioTimeList from './reducer-timeList';
import errorReducer from "./reducer-errors";


const allReducers = combineReducers({
  dimensions: DimensionsReducer,
  objFile: ObjFileReducer,
  vtpFile: VtpFileReducer,
  scenario: ScenarioReducer,
  scenarioList: ScenarioListReducer,
  scenarioSummaryTable: ScenarioSummaryReducer,
  scenarioTimeList: ScenarioTimeList,
  security: securityReducer,
  errors: errorReducer,
});

export default allReducers
