import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { styles } from './chartStyles.js';
import { localhost } from "../../../actions/index";

class ScenarioDescription extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    if ( this.props.dimensions == null ) return null;
    const width = this.props.dimensions.width * 0.38;
    const height = this.props.dimensions.height * 0.85/2;
    if(this.props.scenario === null) return null;
    const scenarioName=this.props.scenario.id.scenarioName;
    const url = `${localhost}/blast/api/users/loadImage?scenarioName=${scenarioName}`;
    return (
      <div style={styles.row}>
        <div style={styles.smallContainerImage}>
          <img
            src={url}
            alt="new"
            style={styles.imageView}
          />
        </div>
        <div style={styles.smallContainerDescription}>
          <div style={styles.titleStyle}>Scenario Description
          </div>
          <div style={styles.textStyle}>
          {this.props.scenario.description}
          </div>
        </div>


      </div>
    );
  }
}

function mapStateToProps(state) {

  return {
    scenario: state.scenario,
    dimensions: state.dimensions
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

//export default TimeList;
export default connect(mapStateToProps, mapDispatchToProps)(ScenarioDescription);
