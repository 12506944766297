//
import axios from "axios";
import Images from "../images/index";
export const GET_ERRORS = "GET_ERRORS";
//export const localhost = "http://localhost:5008";
//export const localhost = "https://cap-loadbal-fargate-services-1941978929.us-east-1.elb.amazonaws.com";
export const localhost = "https://blastapi.health-map.net";
export const CONTEXT = "3D Viewer";
export const USER_FETCH_SUCCESS = "USER_FETCH_SUCCESS";
export const UPDATE_DIMENSIONS = "UPDATE_DIMENSIONS";
export const UPDATE_SCENARIO_FRAME = "UPDATE_SCENARIO_FRAME";
export const FETCH_OBJ_FILE = "FETCH_OBJ_FILE";
export const FETCH_VTP_FILE = "FETCH_VTP_FILE";
export const CLEAR_OBJ_FILE = "CLEAR_OBJ_FILE";
export const CLEAR_VTP_FILE = "CLEAR_VTP_FILE";
export const SELECT_SCENARIO = "SELECT_SCENARIO";
export const GET_SCENARIO_LIST = "GET_SCENARIO_LIST";
const user = "user";

export function getConfig() {
  const jwtToken = sessionStorage.getItem("jwtToken");
  const config = {
    headers: {
      Authorization: jwtToken,
    },
  };
  return config;
}

export function updateDimensions(width, height) {
  return {
    type: UPDATE_DIMENSIONS,
    payload: { width: width, height: height },
  };
}

export function getImage() {
  return Images.blast;
}

export function clearObj() {
  return {
    type: CLEAR_OBJ_FILE,
    payload: null,
  };
}

export function updateScenarioFrame(frame) {
  //const SELECT_FRAME_URL = `${localhost}/updateFrame`;
  //const url = `${SELECT_FRAME_URL}?frameChange=${change}`;
  //const request = axios.get(url);
  return {
    type: UPDATE_SCENARIO_FRAME,
    payload: frame,
  };
}

export const selectScenario = (scenarioName, number) => {
  const user = "user";
  return (dispatch) => {
    const SELECT_SCENARIO_URL = `${localhost}/blast/selectScenario`;
    const url = `${SELECT_SCENARIO_URL}?scenarioName=${scenarioName}&user=${user}`;
    axios
      .get(url, getConfig)
      .then((response) => {
        dispatch({
          type: SELECT_SCENARIO,
          payload: response,
        });
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
          });
        } else {
          alert("error ", err);
        }
      });
  };
};

export const getScenarioList = () => {
  const user = "user";
  return (dispatch) => {
    const GET_SCENARIO_URL = `${localhost}/blast/getScenarioList`;
    const url = `${GET_SCENARIO_URL}?user=user`;
    axios
      .get(url, getConfig)
      .then((response) => {
        dispatch({
          type: GET_SCENARIO_LIST,
          payload: response,
        });
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
          });
        } else {
          alert("error ", err);
        }
      });
  };
};
