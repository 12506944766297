import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getImage } from "../../actions";
import logo from "../../images/CFDResearch_SVG.svg";
import Image3dView from "../images3d/gltfView";

const LandingPage = () => (
  <div>
    <h1 style={styles.Header}>Landing Page</h1>
  </div>
);

class LandingForm extends Component {
  getFontSize() {
    let aFontSize = 24;
    if (this.props.dimensions) {
      aFontSize = this.props.dimensions.height / 22;
    }
    let myFontSize = {
      color: "black",
      width: "100%",
      fontSize: aFontSize,
      fontFamily: "Verdana",
      textAlign: "left",
      marginTop: 20,
    };
    return myFontSize;
  }

  render() {
    const image = getImage();
    const welcometext = "Welcome: Please Sign In";
    return (
      <div style={styles.signInMain}>
        <div style={styles.left}>
          <div style={styles.bullets}>
            <ul>
              <li style={this.getFontSize()}>Monitor</li>
              <li style={this.getFontSize()}>Track </li>
              <li style={this.getFontSize()}>Understand </li>
              <li style={this.getFontSize()}>Ensure Safety</li>
            </ul>
          </div>
          <div style={styles.logo}>
            <img src={logo} alt="CFD Research" />
          </div>
        </div>
        <div className="shadow p-3 mb-5 bg-grey rounded" style={styles.right}>
          {/**<Image3dView userId={0} time={0} />**/}
          <img src={image} style={styles.rightImage} alt="" />
        </div>
      </div>
    );
  }
}

//{ error && <p>{error.message}</p> }

function mapStateToProps(state) {
  return {
    //message: state.auth.message,
    dimensions: state.dimensions,
  };
}

//export default connect(mapStateToProps)(Header);
//export default connect(mapStateToProps, mapDispatchToProps)(SignInForm);
export default withRouter(
  connect(
    mapStateToProps,
    null
  )(LandingForm)
);

export { LandingPage };

const styles = {
  Header: {
    color: "white",
    width: "100%",
    fontSize: 30,
    fontFamily: "Brush Script Std",
    fontStyle: "italic",
    textAlign: "center",
    //backgroundColor: 'white',
  },
  Header2: {
    color: "black",
    width: "100%",
    fontFamily: "Brush Script Std",
    fontStyle: "italic",
    textAlign: "left",
    marginTop: 20,
    //backgroundColor: 'white',
  },
  signInMain: {
    flex: 1,
    //height: 1400,
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    backgroundColor: "transparent",
    display: "flex",
    flexDirection: "row",
  },
  rightImage: {
    width: "100%",
    height: "100%",
    backgroundColor: "transparent",
    display: "flex",
    flexDirection: "row",
    //backgroundImage: `url(${Background})`
  },

  right: {
    width: "65%",
    height: "70%",
    marginLeft: "10%",
    marginRight: "10%",
    marginTop: "5%",
    marginBottom: "10%",
    backgroundColor: "transparent",
  },
  logo: {
    width: "90%",
    height: "20%",
  },

  left: {
    width: "35%",
    height: "80%",
    marginLeft: "5%",
    marginRight: "5%",
    marginTop: "5%",
    backgroundColor: "transparent",
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  message: {
    //flex:1,
    height: "15%",
    marginLeft: 10,
    marginRight: 0,
    marginTop: 25,
    backgroundColor: "transparent",
    display: "flex",
    //flexDirection: 'column',
  },
  bullets: {
    flex: 1,
    height: "60%",
    marginLeft: 60,
    marginRight: 0,
    marginTop: 0,
    paddingTop: 0,
    backgroundColor: "transparent",
    //display: 'flex',
    flexDirection: "column",
  },
};
