import { UPDATE_DIMENSIONS } from '../actions/index';

const initialState = {
    height: 0,
    width:0
  };

export default function (state = initialState, action) {
    switch (action.type) {
        case UPDATE_DIMENSIONS:
          return action.payload;
        default:
          return state;
    }
}
