/*************************************
 * author: katherine marschner
 * cfd copyright 2020
 *************************************/
import React, { Component } from "react";


class GLTFViewer extends Component {
  render() {
    const gltfPath = `${process.env.PUBLIC_URL}/models2/gltfexport.gltf`;
    return (
      <div
        className="shadow p-3 mb-5 bg-grey rounded"
        style={{
          height: "90%",
          width: "90%",
          marginRight: "5%",
          marginTop: "3%",
          marginLeft: "5%",
          marginBottom: "5%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <model-viewer
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "black",
          }}
          src={gltfPath}
          autoplay
          alt="not found"
          auto-rotate
          camera-controls
        ></model-viewer>
      </div>
    );
  }
}

export default GLTFViewer;
