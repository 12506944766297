import React, { Component } from "react";
//import { NavLink, withRouter } from 'react-router-dom';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Button, ButtonToolbar, Glyphicon } from "react-bootstrap";

import {
  selectScenario,
  clearObj,
  updateScenarioFrame,
  fetchVtp,
  getScenarioList,
} from "../../../actions";

class PlayerControls extends Component {
  constructor(props) {
    super(props);
    this.onForward = this.onForward.bind(this);
    this.onBack = this.onBack.bind(this);
    this.onPlay = this.onPlay.bind(this);
    this.onPause = this.onPause.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.renderSelectScenario = this.renderSelectScenario.bind(this);
    this.props.getScenarioList();
    this.state = {
      play: false,
    };
  }

  componentDidUpdate(prevProps) {
    //  if (this.state.play){
    //    this.props.updateFrame(this.props.frame + 1);
    //  }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  handleChange(event) {
    event.preventDefault();
    let obj = JSON.parse(event.target.value);
    if (obj != null) {
      this.props.selectScenario(obj.id.scenarioName, 0);
    }
  }

  onForward() {
    if (this.props.scenario == null) return;
    let newFrame = 0;
    const totalFrames = this.props.scenario.frameList.length;
    if (this.props.scenario.selectedFrame + 1 > totalFrames - 1) newFrame = 0;
    else {
      newFrame = this.props.scenario.selectedFrame + 1;
    }
    this.props.updateScenarioFrame(newFrame);
  }

  onBack() {
    if (this.props.scenario == null) return;
    let newFrame = 0;
    const totalFrames = this.props.scenario.frameList.length;
    if (this.props.scenario.selectedFrame - 1 < 0) {
      newFrame = totalFrames - 1;
    } else {
      newFrame = this.props.scenario.selectedFrame - 1;
    }
    this.props.updateScenarioFrame(newFrame);
  }

  onPlay() {
    if (this.state.play === false) {
      this.interval = setInterval(() => {
        this.onForward();
      }, 1000);
      setTimeout(() => {
        clearInterval(this.interval);
        this.setState({ play: false });
      }, 30000);
      this.setState({ play: true });
    }
  }

  onPause() {
    clearInterval(this.interval);
    clearTimeout();
    this.setState({ play: false });
  }

  onReset(values) {}

  renderOption(scenario, index) {
    return (
      <option key={scenario.id.scenarioName} value={JSON.stringify(scenario)}>
         
        {scenario.scenarioPath} 
      </option>
    );
  }

  // defaultValue={JSON.stringify(defaultScenario)}
  renderSelectScenario() {
    const scenarioList = this.props.scenarioList;
    if (scenarioList === null || scenarioList === "") return;
    const defaultScenario = scenarioList.length > 0 ? scenarioList[0] : "";
    console.log("hello");
    console.log(this.props.scenario.scenarioPath);
    //value={JSON.stringify(this.props.scenario)}
    return (
      <div style={styles.MenuScenario}>
        <label style={styles.textWhite}> Choose Scenario: </label>
        <select
          style={styles.MenuOption}
          default={this.props.scenario.scenarioPath}
          onChange={this.handleChange}
        >
          {scenarioList.map((scenario, index) =>
            this.renderOption(scenario, index)
          )}
        </select>
      </div>
    );
  }

  render() {
    const back = "<";
    const forward = ">";
    const play = ">>";
    const pause = "||";
    return (
      <div style={styles.row}>
        {this.renderSelectScenario()}
        <div style={styles.buttonToolBar}>
          <ButtonToolbar>
            <Button variant="link" onClick={this.onBack.bind(this)}>
              {back}
            </Button>
            <Button variant="link" onClick={this.onPlay.bind(this)}>
              {play}
            </Button>
            <Button variant="link" onClick={this.onPause.bind(this)}>
              {pause}
            </Button>
            <Button variant="link" onClick={this.onForward.bind(this)}>
              {forward}
            </Button>
          </ButtonToolbar>
        </div>
      </div>
    );
  }
}

//<div style={styles.buttonDiv}>
//  <button type="submit" className="btn btn-primary" onClick={this.onForward} style={styles.button}> forward </button>
//  <button type="submit" className="btn btn-primary" onClick={this.onBack} style={styles.button}> back </button>
//</div>

//<img src={logo} alt="My logo" />

function mapStateToProps(state) {
  return {
    //frame : state.frame,
    scenarioList: state.scenarioList,
    scenario: state.scenario,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      selectScenario,
      updateScenarioFrame,
      clearObj,
      getScenarioList,
    },
    dispatch
  );
}

// You have to connect() to any reducers that you wish to connect to yourself**/
PlayerControls = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayerControls);
export default PlayerControls;

const styles = {
  row: {
    flex: 1,
    //height: 1400,
    width: "100%",
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    backgroundColor: "transparent",
    display: "flex",
    flexDirection: "row",

    justifyContent: "space-between",
  },

  textWhite: {
    color: "white",
    justifyContent: "center",
    fontSize: 16,
    fontFamily: "Verdana",

    textAlign: "right",
    marginRight: 8,
    //backgroundColor: 'white',
  },

  button: {
    width: "100%",
    color: "white",
    alignItems: "flex-end",
    //justifyContents: 'space-between',
    marginLeft: 0,
    marginRight: 0,
  },
  buttonToolBar: {
    //width: '30%',
    height: "100%",
    color: "white",
    //alignItems: 'right',
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 0,
    marginRight: 10,
  },

  MenuScenario: {
    //float:'right',
    //justifyContents: 'center',
    alignItems: "center",
    //display: 'block',
    //width: '50%',
    marginTop: 5,
    marginLeft: 10,
    backgroundColor: "transparent",
  },

  MenuOption: {
    textDecoration: "none",
    justifyContents: "center",
    textAlign: "center",
    fontSize: 24,
    fontFamily: "Verdana",
    //fontStyle: 'italic',
    color: "black",
  },
};

/**
 <div>
   <input type="file" id="fileLoader" name="files" title="Load File" onclick="openfileDialog()"/>
   <input type="button" id="btnOpenFileDialog" value = "Click Me !!!" onclick="openfileDialog();" />
 </div>
 **/
