import React, { Component } from "react";

import { NavLink } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Charts from "./charts";
import ScenarioDescription from "./scenarioDescription";
import {  Table } from "react-bootstrap";

import {
  selectScenario,
  clearObj,
  fetchVtp
} from "../../../actions";

class ScenarioDetails extends Component {
  constructor(props) {
    super(props);
    this.createTable = this.createTable.bind(this);
    this.createRow = this.createRow.bind(this);
    this.createHeader = this.createHeader.bind(this);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

 createRow(row, index) {
    return (
      <tr key={index}>
        <td style={styles.text}>{row.id.soldier}</td>
        <td style={styles.text}>{row.id.bodyPart}</td>
        <td style={styles.text}>{row.peakForce.toFixed(3)}</td>
        <td style={styles.text}>{row.peakPressure.toFixed(2)}</td>
        <td style={styles.text}>{row.peakImpulse.toFixed(3)}</td>
        <td style={styles.text}>{row.peakPositiveImpulse.toFixed(3)}</td>
        <td style={styles.text}>{row.peakNegativeImpulse.toFixed(3)}</td>
      </tr>
    );
  }
  createHeader(header, index){
    return (
      <td key={index} style={styles.text}>{header}</td>
    )
  }

  createTable() {
    return (
      <Table size="sm"  hover variant="light" striped >
        <thead>
          <tr>
          {this.props.scenarioSummaryTable.headerList.map((header, index) => this.createHeader(header, index))}
          </tr>
        </thead>
        <tbody>
          {this.props.scenarioSummaryTable.displayList.map((row, index) => this.createRow(row, index))}
        </tbody>
      </Table>
    );
  }

  render() {
    if (this.props.scenarioSummaryTable === null) return null;
    return (
      <div>
        <div>
          <ScenarioDescription/>
        </div>
        <div>
          <text style={styles.textRadio}>{this.props.scenarioSummaryTable.title}</text>
        </div>
        <div style={styles.tableContainer}>
          {this.createTable()}
        </div>
        <Charts height={"100%"} width={"100%"} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    scenarioSummaryTable: state.scenarioSummaryTable,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      selectScenario,
      clearObj
    },
    dispatch
  );
}

// You have to connect() to any reducers that you wish to connect to yourself**/
ScenarioDetails = connect(
  mapStateToProps,
  mapDispatchToProps
)(ScenarioDetails);
export default ScenarioDetails;

const styles = {
  Header: {
    color: "black",
    width: "100%",
    fontSize: 30,
    fontFamily: "Verdana",
    fontStyle: "italic",
    textAlign: "center",
    justifyContents: "center"
  },

  ProfileMain: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContents: "center",
    width: "100%",
    marginLeft: 0,
    marginRight: 20,
    marginTop: 0,
    backgroundColor: "transparent",
    overflow: "auto"
  },
  tableContainer: {
      width: '98%',
      marginLeft: 2,
      marginRight: 2,
      marginBottom: 20,
      border: 2,
      backgroundColor: '#eee',
      //backgroundSize: 'cover',
      borderColor: 'black',
      borderStyle: 'solid'
    },

  row: {
    flex: 1,
    //height: 1400,
    width: "100%",
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    backgroundColor: "transparent",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContents: "space-around"
  },
  column: {
    flex: 1,
    //height: 1400,
    width: "100%",
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    backgroundColor: "transparent",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContents: "space-around"
  },

  rowSimple: {
    flex: 1,
    //height: 1400,
    width: 200,
    marginLeft: 0,
    marginRight: 0,
    //marginTop: 10,
    backgroundColor: "transparent",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContents: "center"
  },
  text: {
    color: "black",
    fontSize: 14,
    textAlign: "center"
  },
  error: {
    color: "#B22222",
    width: "50%",
    fontSize: 18,
    fontFamily: "Verdana",
    fontStyle: "italic",
    textAlign: "right",
    marginRight: 20
    //backgroundColor: 'white',
  },
  textRadio: {
    color: "black",
    width: "50%",
    fontSize: 18,
    fontFamily: "Verdana",
    fontStyle: "italic",
    textAlign: "left",
    marginRight: 0
    //backgroundColor: 'white',
  },

  buttonDiv: {
    color: "white",
    //alignItems: 'center',
    //justifyContents: 'space-between',
    marginTop: 20,
    flexDirection: "row"
  },
  button: {
    width: "100%",
    color: "white",
    alignItems: "center",
    justifyContents: "space-between",
    marginLeft: 0,
    marginRight: 0,
    float: "right"
  }
};

/**
 <div>
   <input type="file" id="fileLoader" name="files" title="Load File" onclick="openfileDialog()"/>
   <input type="button" id="btnOpenFileDialog" value = "Click Me !!!" onclick="openfileDialog();" />
 </div>
 **/
