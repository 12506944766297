import React from 'react';
import ReactDOM from "react-dom";
import { BrowserRouter } from 'react-router-dom';
import {Provider} from 'react-redux';
import {createStore, applyMiddleware} from 'redux';
import ReduxThunk from 'redux-thunk';
import allReducers from './reducers';
import App from './components/App';
//import javascriptviewer from'./
const createStoreWithMiddleware = applyMiddleware(ReduxThunk)(createStore);

export const store = createStoreWithMiddleware(allReducers);

ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter>
      <App/>
		</BrowserRouter>
  </Provider>,
  document.getElementById('MyContainer')
);
