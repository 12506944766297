import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {
  logout,
  updateDimensions
} from '../../actions';
import * as routes from '../routes';
import { withRouter } from 'react-router-dom';

class Header extends Component {

  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 };
    this.renderLinks = this.renderLinks.bind(this);
    this.renderTabs = this.renderTabs.bind(this);
    this.normalizeNavBar = this.normalizeNavBar.bind(this);
    this.normalizeSignIn = this.normalizeSignIn.bind(this);
    this.normalizeTitle = this.normalizeTitle.bind(this);
    this.signOut = this.signOut.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    //window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
    if (this.props.updateDimensions) {
      this.props.updateDimensions(window.innerWidth, window.innerHeight);
    }
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);

  }

  signOut() {
    this.props.logout();
    this.props.history.push(routes.LANDING);
  }

  renderLinks() {
    const fs = this.normalizeSignIn();
    return [
      <li key={1} style={styles.MenuRIsign}>
        <NavLink style={{ ...styles.menuButtonStyle, ...{ fontSize: fs } }} to={routes.LOGIN} >Sign In</NavLink>
      </li>,
      <li key={2} style={styles.MenuRIsign}>
        <NavLink style={{ ...styles.menuButtonStyle, ...{ fontSize: fs } }} to={routes.REGISTER} >Sign Up</NavLink>
      </li>
    ];

  }

  normalizeNavBar() {
    if (this.state.width > 1400) {
      return 24;
    } else if (this.state.width > 1000) {
      return 20;
    } else if (this.state.width > 800) {
      return 16;
    } else {
      return 10;
    }
  }

  normalizeTitle() {
    if (this.state.width > 1400) {
      return 50;
    } else if (this.state.width > 1000) {
      return 40;
    } else if (this.state.width > 800) {
      return 30;
    } else {
      return 20;
    }
  }

  normalizeSignIn() {
    if (this.state.width > 1400) {
      return 24;
    } else if (this.state.width > 1000) {
      return 20;
    } else if (this.state.width > 800) {
      return 16;
    } else {
      return 12;
    }
  }

  renderTabs() {
    const fs = this.normalizeSignIn();

    return (
      <div >
        <ul style={styles.MenuLIDiv}>
          <div style={{ marginLeft: 50 }}>
            <li style={styles.MenuLI}>
              <NavLink activeClassName="selected" style={{ ...styles.menuButtonStyle, ...{ fontSize: fs } }} activeStyle={{ color: 'blue' }} to={routes.LANDING}>Home</NavLink>
            </li>
            <li style={styles.MenuLI}>
              <NavLink activeClassName="selected" style={{ ...styles.menuButtonStyle, ...{ fontSize: fs } }} activeStyle={{ color: 'blue' }} to={routes.VIEWER}>Viewer</NavLink>
            </li>
          </div>

          <li key={1} style={styles.MenuRIsign}>
            <button style={{ ...styles.menuButtonStyle, ...{ fontSize: fs } }} type="button" onClick={this.signOut} >
              Sign Out
                </button>
          </li>
        </ul>
      </div>
    );

  }

  render() {

    const { validToken, user } = this.props.security;
    const validLogin = (validToken && user);
    const title = "CoBi-Blast Virtual Weapon Training";

    const fs = this.normalizeTitle();
    // const version = "admin"
    //const name = this.props.security ? ` welcome  ${this.props.security.user.fullName}` : "";

    return (
      <div style={styles.Header}>
        <div style={styles.TitleContainer}>
          <p style={{ ...styles.titleStyle, ...{ fontSize: fs } }}>{title} </p>
        </div>

        <div style={styles.Menu}>
          <nav>
            {!validLogin ? null : this.renderTabs()}
            <ul height='100%'>
              {!validLogin ? this.renderLinks(validToken, user) : null}
            </ul>
          </nav>
        </div>

      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    logout,
    updateDimensions
  }, dispatch);
}

function mapStateToProps(state) {
  return {
    security: state.security,
    errors: state.errors,
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
//export default connect(mapStateToProps, mapDispatchToProps)(Header);


const styles = {

  Header: {
    height: '15%',
    backgroundColor: '#ccc',
    width: '100%',
    fontSize: 30,
  },

  TitleContainer: {
    height: '60%',
    color: 'black',
    width: '100%',
    backgroundColor: 'transparent',
    marginBottom: 0,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },

  titleStyle: {
    marginTop: 20,
    height: '100%',
    color: 'black',
    fontSize: 24,
    fontFamily: 'Verdana',
    fontWeight: 'bold',
    backgroundColor: 'transparent',
    marginBottom: 0,
  },

  menuButtonStyle: {
    textDecoration: 'none',
    color: 'blue',
    fontSize: 20,
    fontFamily: 'Brush Script Std',
    background: 'none',
    border: 'none',
    margin: 0,
    marginRight: 10,
    padding: 0,
    listStyleType: 'none',
    float: 'right',
    display: 'block',
    width: 'auto',
    height: '100%',
    alignItems: 'flex-end',
  },

  Menu: {
    height: '30%',
    //flexDirection: 'column',
    backgroundColor: 'transparent',
  },

  MenuLI: {
    marginRight: 40,
    listStyleType: 'none',
    float: 'left',
    display: 'block',
    //width: '10%',
    //height: 60,
    textAlign: 'center',
    //lineHeight: 55,
  },

  MenuLIA: {
    textDecoration: 'none',
    color: 'black',
    textAlign: 'center',
    fontSize: 24,
    fontFamily: 'Verdana',
    //fontStyle: 'italic',
    fontWeight: 'bold',
  },

  MenuRIsign: {
    textDecoration: 'none',
    listStyleType: 'none',
    float: 'right',
    display: 'block',
    width: 120,
    height: '100%',
    textAlign: 'bottom',
    marginRight: 50,
    //lineHeight: 0,
  },


  MenuLIAsign: {
    textDecoration: 'none',
    //textAlign: 'center',
    fontSize: 22,
    fontFamily: 'Brush Script Std',
    fontStyle: 'italic',
  },
}
