import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ScaleLoader } from "react-spinners";
import vtkXMLPolyDataReader from "vtk.js/Sources/IO/XML/XMLPolyDataReader";
import vtkFullScreenRenderWindow from "vtk.js/Sources/Rendering/Misc/FullScreenRenderWindow";
import vtkActor from "vtk.js/Sources/Rendering/Core/Actor";
import vtkMapper from "vtk.js/Sources/Rendering/Core/Mapper";
import vtkColorTransferFunction from "vtk.js/Sources/Rendering/Core/ColorTransferFunction";
import vtkRenderWindow from "vtk.js/Sources/Rendering/Core/RenderWindow";
//import style from "./style.module.css";
import vtk from "vtk.js/Sources/vtk";
import vtkCamera from "vtk.js/Sources/Rendering/Core/Camera";
import vtkColorMaps from "vtk.js/Sources/Rendering/Core/ColorTransferFunction/ColorMaps";
import {
  ColorMode,
  ScalarMode
} from "vtk.js/Sources/Rendering/Core/Mapper/Constants";
import { localhost } from "../../../actions/index";

class VTKViewer extends Component {
  constructor(props) {
    super(props);

    this.renderModel = this.renderModel.bind(this);

    //this.renderModel2 = this.renderModel2.bind(this);
    this.state = {
      fullScreenRenderer: null,
      renderer: null,
      renderWindow: null,
      reader: null,
      scenarioName: "Gunner",
      frame: 0,
      actor: null,
      cam: null
    };
  }

  componentDidMount() {

    const rootContainer = ReactDOM.findDOMNode(this);
    const fullScreenRenderer = vtkFullScreenRenderWindow.newInstance({
      background: [0, 0, 0],
      rootContainer: rootContainer,
      containerStyle: { height: "70%", width: "55%", position: "absolute" }
    });
    const renderer = fullScreenRenderer.getRenderer();
    const cam = vtkCamera.newInstance();
    cam.setFocalPoint(0, 0, 0);
    cam.setPosition(0, -4, -6);
    cam.roll(180);
    cam.dolly(1.5);
    renderer.setActiveCamera(cam);
    this.setState({
      fullScreenRenderer: fullScreenRenderer,
      renderer: renderer,
      renderWindow: fullScreenRenderer.getRenderWindow(),
      reader: vtkXMLPolyDataReader.newInstance(),
      actor: vtkActor.newInstance(),
      cam: cam
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.scenario === null)
      return;

    this.renderModel(this.props.scenario);
  }

  renderModel(scenario) {

    //const rootContainer = ReactDOM.findDOMNode(this);
    let textCtx = null;
    //let dims = null;
    let background = [0, 0, 0];


    const fullScreenRenderer = this.state.fullScreenRenderer;
    const renderer = this.state.renderer;
    const renderWindow = this.state.renderWindow;
    const reader = this.state.reader;
    const frame = scenario.selectedFrame;
    const folder = scenario.id.scenarioName;

    reader
      .setUrl(`${localhost}/blast/api/users/fetchVtp?frame=${frame}&folder=${folder}`)
      .then(() => {
        const lookupTable = vtkColorTransferFunction.newInstance();
        const polydata = reader.getOutputData(0);

        const mapper = vtkMapper.newInstance({
          interpolateScalarsBeforeMapping: true,
          useLookupTableScalarRange: true,
          lookupTable,
          scalarVisibility: true,
          colorByArrayName: "Pressure",
          scalarMode: ScalarMode.USE_POINT_FIELD_DATA
        });
        //const actor = vtkActor.newInstance();
        const actor = this.state.actor;
        const scalars = polydata.getPointData().getArrays()[0];
        const dataRange = [].concat(scalars ? scalars.getRange() : [0, 1]);
        const preset = vtkColorMaps.getPresetByName("Cool to Warm");
        //mapper.setColorByArrayName("Pressure");
        //mapper.setScalarMode(ScalarMode.USE_POINT_FIELD_DATA);
        lookupTable.applyColorMap(preset);
        lookupTable.setMappingRange(dataRange[0], dataRange[1]);
        lookupTable.updateRange();
        actor.setMapper(mapper);
        mapper.setInputData(polydata);
        mapper.setLookupTable(lookupTable);

        renderer.addActor(actor);
        //renderer.resetCamera();
        //const cam = vtkCamera.newInstance();
        //const cam = this.state.cam;
        //renderer.setActiveCamera(cam);
        //cam.setFocalPoint(0, 0, 0);
        //cam.setPosition(0,-4, -6);
        //cam.roll(180);
        renderWindow.render();
      });
  }
  componentWillReceiveProps() {
    //this.setState({allowUpdate: true})
  }

  componentDidCatch(error, info) {
    console.log(error, info);
  }

  render() {

    return (
      <div
        className={"vtk"}
        id={"vtk"}
        style={{
          width: "55%",
          height: "100%",
          overflow: "hidden",
          marginBottom: 20,
          marginLeft: 20,
          marginRight: 20
        }}
      >
        {/**this.renderModelSmall()**/}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    //vtpFile: state.vtpFile,
    scenario: state.scenario,
    frame: state.frame
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VTKViewer);
//module.exports = OBJViewer;

/**const lookupTable = vtkColorTransferFunction.newInstance();
const source = vtpReader.getOutputData(0);
const mapper = vtkMapper.newInstance({
  interpolateScalarsBeforeMapping: false,
  useLookupTableScalarRange: true,
    lookupTable,
    scalarVisibility: false,
  });
  const actor = vtkActor.newInstance();
  const scalars = source.getPointData().getScalars();
  const dataRange = [].concat(scalars ? scalars.getRange() : [0, 1]);
  let activeArray = vtkDataArray;
  const cam = vtkCamera.newInstance();
  renderer.setActiveCamera(cam);
  cam.setFocalPoint(0, 0, 0);
  cam.setPosition(0, 0, 10);
  actor.setMapper(mapper);
  mapper.setInputData(polydata);
  renderer.addActor(actor);
  renderer.resetCamera();
  renderWindow.render();**/
