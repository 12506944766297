import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import Header from './mainTabs/header';
import Viewer from './mainTabs/mainViewerVtp';
import Register from './mainTabs/authorize/Register'
import Login from './mainTabs/authorize/Login'
import LandingPage from './mainTabs/landing';
import * as routes from './routes';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authUser: null,
    };
  }

  render() {
    return (
      <div style={styles.BlastContainer}  >
        <Header authUser={null} version={""} />
        <div style={styles.Mainbody}>
          <Switch>
            <Route exact strict path={routes.LANDING} component={() => <LandingPage />} />
            <Route exact path={routes.REGISTER} component={Register} />
            <Route exact path={routes.LOGIN} component={Login} />
            <Route exact path={routes.VIEWER} component={() => <Viewer />} />
          </Switch>
        </div>
      </div>
    );
  }
}


export default (App)
const styles = {
  BlastContainer: {
    //flex:1,
    height: '100%',
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    backgroundColor: 'transparent',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
 
  Mainbody: {
    height: '85%',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    textAlign: 'center',
    display: 'flex',
    backgroundColor: "transparent",
    //  overflow: auto,
  },

  Menu: {
    height: '5%',
    backgroundColor: '#DBDDE8',

  },

  MenuUL: {
    margin: 0,
    padding: 0,
  },
  MenuLI: {
    textDecoration: 'none',
    listStyleType: 'none',
    float: 'left',
    display: 'block',
    width: 250,
    //height: 60,
    textAlign: 'center',
    //lineHeight: 55,
    fontFamily: 'Tahoma',
    fontSize: 40,

  },
  MenuLIA: {
    textDecoration: 'none',
    color: 'black',
    fontFamily: 'Tahoma',
    textAlign: 'center',
    fontSize: 25,
    fontWeight: 'bold',

  },

  MenuLIHover: {
    backgroundColor: 'green',
  }
}

