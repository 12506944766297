
import Background from './smallBackground.png';
export const styles = {
  //******************containers*************************
  scrollView: {
    flex: 1,
  },

  allChartContainer: {
      width: '100%',
      height: '100%',
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      marginBottom: 5,
      //overflow: 'auto',

    },
    imageView:{
      marginTop: 20,
      width: '80%'
    },

    row: {
      display: "flex",
      flexDirection: 'row',
      marginLeft:2,
      marginRight:2,
      justifyContent: 'space-between',
      width: '98%',
      backgroundImage: `url(${Background})`,
      border: 2,
      borderColor: 'black',
      borderStyle: 'solid'
    },

    smallContainerDescription: {
      width: '65%',
      //marginLeft: 2,
      //marginRight: 5,
      overflow: 'auto',

    },
    smallContainerImage: {
      width: '35%',
      //marginLeft: 2,
      //marginTop:5,
      marginBottom:5,
      //marginRight: 5,

    },

    chartContainer: {
        width: '98%',
        height: 250,
        //marginLeft: 2,
        marginRight: 5,
        marginBottom: 20,
        border: 2,
        backgroundColor: 'transparent',
        //backgroundSize: 'cover',
        borderColor: 'black',
        borderStyle: 'solid'
      },


      titleStyle: {
          textAlign: 'left',
          //color: 'white',
          width: '100%',
          fontWeight: 'bold',
          fontSize: 18,
          //marginTop: 5,
          whiteSpace: 'pre-wrap',
        },
        textStyle: {
            textAlign: 'left',
            //height: '10%',
            width: '100%',
            //color: 'white',
            fontWeight: 'bold',
            fontSize: 14,
            marginTop: 10,
            whiteSpace: 'pre-wrap',
          },

};
