import axios from "axios";
import setJWTToken from "../securityUtils/setJWTToken";
import jwt_decode from "jwt-decode";
import { localhost, GET_ERRORS } from "./index";
//for authorization
export const EMAIL_CHANGED = "email_changed";
export const PASSWORD_CHANGED = "password_changed";
export const LOGIN_STATUS = "LOGIN_STATUS";
export const LOGOUT_USER = "LOGOUT_USER";
export const USER_NAME_SESSION_ATTRIBUTE_NAME = 'authenticatedUser'
export const SET_CURRENT_USER = 'SET_CURRENT_USER'

//export const createNewUser = (newUser, callback) => async dispatch => {
export const createNewUser = (newUser, callback) => {
    return dispatch => {
        axios.post(`${localhost}/blast/api/users/register`, newUser)
            .then(response => {
                callback.push("/login");
                dispatch({
                    type: GET_ERRORS,
                    payload: {}
                });
            })
            .catch(err => {
                if (err.response && err.response.data) {
                    dispatch({
                        type: GET_ERRORS,
                        payload: err.response.data
                    });
                }
            })
    }
}

export const login = (LoginRequest) => {
    return dispatch => {
        axios.post(`${localhost}/blast/api/users/login`, LoginRequest)
            .then(response => {
                // extract token from res.data
                const { token } = response.data;
                // store the token in the sessionStorage
                sessionStorage.setItem("jwtToken", token);
                // set our token in header ***
                setJWTToken(token);
                //setupAxiosInterceptors(token);
                // decode token on React
                const decoded = jwt_decode(token);
                // dispatch to our securityReducer
                dispatch({
                    type: SET_CURRENT_USER,
                    payload: decoded
                });
            })
            .catch(err => {
                if (err.response && err.response.data) {
                    dispatch({
                        type: GET_ERRORS,
                        payload: err.response.data
                    });
                }
            })
    }
}


export function setupAxiosInterceptors(token) {
    axios.interceptors.request.use(
        (config) => {
            if (token) {
                config.headers.authorization = token
            }
            return config;
        }
    )
}

export const logout = () => dispatch => {
    sessionStorage.removeItem("jwtToken");
    setJWTToken(false);
    dispatch({
        type: SET_CURRENT_USER,
        payload: null
    });
};

