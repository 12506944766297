/*
 * All reducers get two parameters passed in, state and action that occurred
 *       > state isn't entire apps state, only the part of state that this reducer is responsible for
 * */

import {FETCH_OBJ_FILE, CLEAR_OBJ_FILE} from '../actions/index';// "state = null" is set so that we don't throw an error when app first boots up

export default function (state = null, action) {

    switch (action.type) {
        case FETCH_OBJ_FILE:
            return action.payload.data;
            //return action.payload;
        case CLEAR_OBJ_FILE:
            return action.payload;
        default:
         	return state;
    }

}
